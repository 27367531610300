import React from 'react';
import { Helmet } from 'react-helmet';
import { compagnyName, eShopURL } from '../config/settings';

const SignatureStyle = {
  borderSpacing: '20px',
};
const ImageWrapper = {
  width: '208px',
  paddingRight: '25px',
};
const ImageWrapperCadoret = {
  width: '208px',
};
const Image = {
  width: '208px',
  height: '208px',
  margin: 'auto',
  display: 'block',
};
const TextWrapper = {
  marginLeft: '20px',
  fontFamily: 'Arial',
  lineHeight: '1.45',
};
const Name = {
  fontSize: '17px',
  margin: '0',
  fontWeight: 'bold',
  lineHeight: '0.97',
};
const Job = {
  margin: 0,
  fontSize: '12px',
};

const contactDetail = {
  listStyle: 'none',
  padding: '2px 0',
};

const contactDetailImg = {
  width: '15px',
  verticalAlign: 'middle',
  marginRight: '5px',
};
const Address = {
  fontSize: '12px',
  margin: '10px 0px 0px 0',
  lineHeight: '1.8',
};

const Mail = {
  margin: '0 0 0 0',
  fontSize: '12px',
  lineHeight: '1.8',
  textDecoration: 'none',
};

const MailLink = {
  textDecoration: 'none',
  color: 'black',
};

const Phone = {
  lineHeight: '1.8',
  fontSize: '12px',
  color: 'black',
  margin: '0 0 0 0',
};
const PhoneLink = {
  textDecoration: 'none',
  color: 'black',
};

const Socials = {
  marginTop: '10px',
};

const Social = {
  //
};
const SocialImg = {
  width: '20px',
  height: '20px',
  marginRight: '10px',
};

const Shop = {
  fontSize: '12px',
  margin: '0px 0 10px 0',
  lineHeight: '1.8',
};

const ShopLink = {
  textDecoration: 'none',
  color: '#a2865b',
};

const logoImg = (signature) => (
  <img
    src={`https://files.myclientisrich.com/${signature.imagePath}/${signature.imageName}`}
    width={Image.width}
    height={Image.height}
    alt={compagnyName}
    style={Image}
  />
);

function telWithoutSpaces(number) {
  return number.replace(/\s/g, '');
}

const Signature = ({ pageContext }) => {
  const { signature } = pageContext;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {signature.name.firstname} {signature.name.lastname} |{' '}
          {compagnyName}
        </title>
      </Helmet>
      <table className="signature" style={SignatureStyle}>
        <tbody>
          <tr>
            <td
              className="image__wrapper"
              style={
                signature.type === 'cadoret'
                  ? ImageWrapperCadoret
                  : ImageWrapper
              }
            >
              {signature.url ? (
                <a
                  href={`https://${signature.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {logoImg(signature)}
                </a>
              ) : (
                logoImg(signature)
              )}
            </td>
            <td className="text__wrapper" style={TextWrapper}>
              <h1 style={Object.assign({}, Name, { color: 'black' })}>
                {signature.name.firstname} {signature.name.lastname}
              </h1>

              <p style={Job}>{signature.job}</p>

              <div style={contactDetail}>
                <p style={Address}>
                  <img
                    src={`https://files.myclientisrich.com/${signature.imagePath}/marker.png`}
                    style={contactDetailImg}
                    alt="Logo Adresse"
                    width={contactDetailImg.width}
                    height={20}
                  />
                  {signature.address.address},{' '}
                  {signature.address.zipcode} {signature.address.city}
                </p>

                <p style={Mail}>
                  <a
                    href={`mailto:${signature.mail}`}
                    target="_blank"
                    rel="noreferrer"
                    style={MailLink}
                  >
                    <img
                      src={`https://files.myclientisrich.com/${signature.imagePath}/mail.png`}
                      style={contactDetailImg}
                      alt="Logo Email"
                      width={contactDetailImg.width}
                      height={10}
                    />
                    {signature.mail}
                  </a>
                </p>

                <p style={Phone}>
                  <a
                    href={`tel:${telWithoutSpaces(
                      signature.tel.fixe
                    )}`}
                    style={PhoneLink}
                  >
                    <img
                      src={`https://files.myclientisrich.com/${signature.imagePath}/phone.png`}
                      style={contactDetailImg}
                      alt="Logo Téléphone"
                      width={contactDetailImg.width}
                      height={15}
                    />
                    Tél. {signature.tel.fixe}
                  </a>
                  <br />
                  {signature.tel.port ? (
                    <>
                      <a
                        href={`tel:${telWithoutSpaces(
                          signature.tel.port
                        )}`}
                        style={PhoneLink}
                      >
                        <img
                          src={`https://files.myclientisrich.com/${signature.imagePath}/phone.png`}
                          style={contactDetailImg}
                          alt="Logo Téléphone"
                          width={contactDetailImg.width}
                          height={15}
                        />
                        Port. {signature.tel.port}
                      </a>
                      <br />
                    </>
                  ) : null}
                </p>
                {signature.type === 'cadoret' ? (
                  <li style={Shop}>
                    <a
                      href={eShopURL}
                      style={ShopLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`https://files.myclientisrich.com/${signature.imagePath}/shop.png`}
                        style={contactDetailImg}
                        alt="Logo Téléphone"
                        width={contactDetailImg.width}
                        height={14}
                      />
                      Découvrir l’e-shop
                    </a>
                  </li>
                ) : null}
              </div>

              <div style={Socials}>
                {signature.social.fb ? (
                  <>
                    <a
                      href={signature.social.fb}
                      target="_blank"
                      rel="noreferrer"
                      style={Social}
                    >
                      <img
                        src={`https://files.myclientisrich.com/${signature.imagePath}/fb.png`}
                        alt="Logo Facebook"
                        style={SocialImg}
                        width={SocialImg.width}
                        height={SocialImg.height}
                      />
                    </a>
                  </>
                ) : null}
                {signature.social.insta ? (
                  <>
                    <a
                      href={signature.social.insta}
                      target="_blank"
                      rel="noreferrer"
                      style={Social}
                    >
                      <img
                        src={`https://files.myclientisrich.com/${signature.imagePath}/insta.png`}
                        alt="Logo Instagram"
                        style={SocialImg}
                        width={SocialImg.width}
                        height={SocialImg.height}
                      />
                    </a>
                  </>
                ) : null}
                {signature.social.web ? (
                  <>
                    <a
                      href={signature.social.web}
                      target="_blank"
                      rel="noreferrer"
                      style={Social}
                    >
                      <img
                        src={`https://files.myclientisrich.com/${signature.imagePath}/web.png`}
                        alt="Logo Website"
                        style={SocialImg}
                        width={SocialImg.width}
                        height={SocialImg.height}
                      />
                    </a>
                  </>
                ) : null}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Signature;
